<template>
  <div>
    <div class="row mt-5">
      <div class="col-md-12 col-xl-10 mx-auto text-center">
        <h1 class="text-center">Thank You!</h1>
        <p class="lead text-center mb-4">
          The changes to your account will take effect momentarily.
        </p>
        <b-button variant="primary" :to="{ name: 'projects-new' }"
          >Start a new project</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import FirebaseMixin from "../mixins/Firebase";
import UtilsMixin from "../mixins/Utils";

export default {
  name: "UpgradeSuccess",
  mixins: [FirebaseMixin, UtilsMixin],
  data() {
    return {};
  },
  watch: {},
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    ...mapGetters(["stripeKey"]),
    userUid() {
      return this.userProfile.uid;
    },
  },
  methods: {
    ...mapMutations(["setUser", "setProfile"]),
  },
  created() {},
};
</script>
