import axios from "axios";
import firebase from "firebase";

export default class Users {
  remoteUrl = "https://us-central1-api-faker.cloudfunctions.net/";
  //remoteUrl = "http://localhost:5001/api-faker/us-central1/";

  // Fetch an id token from Firebase for API authentication
  async getToken() {
    const user = firebase.auth().currentUser;
    const response = await user.getIdToken(false);
    return response;
  }

  // Perform on login actions using cloud function
  async onLogin() {
    // Get token for authentication
    const token = await this.getToken();
    // Make API call to remote API
    return axios({
      method: "post",
      url: this.remoteUrl + "users/onLogin",
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment,
      },
    });
  }

  // Provision a user account using cloud function
  async provision(user) {
    // Get token for authentication
    const token = await this.getToken();
    // Make API call to remote API
    return axios({
      method: "post",
      url: this.remoteUrl + "users/provisionAccount",
      data: user,
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment,
      },
    });
  }

  // Create a checkout session using cloud function
  async createCheckoutSession(prices) {
    // Get token for authentication
    const token = await this.getToken();
    // Make API call to remote API
    return axios({
      method: "post",
      url: this.remoteUrl + "billing/createCheckoutSession",
      data: prices,
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment,
      },
    });
  }

  // Upgrade/downgrade a subscription using cloud function
  async upgradeSubscription(price) {
    // Get token for authentication
    const token = await this.getToken();
    // Make API call to remote API
    return axios({
      method: "post",
      url: this.remoteUrl + "billing/upgradeSubscription",
      data: { price: price },
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment,
      },
    });
  }

  // cancel a subscription using cloud function
  async cancelSubscription() {
    // Get token for authentication
    const token = await this.getToken();
    // Make API call to remote API
    return axios({
      method: "post",
      url: this.remoteUrl + "billing/cancelSubscription",
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment,
      },
    });
  }
}
