<template>
  <div>
    <h1 class="h3 mb-3">Plans &amp; Pricing</h1>

    <div class="row">
      <div class="col-md-12 col-xl-10 mx-auto">
        <h1 class="text-center">The Right Plan For You</h1>
        <p class="lead text-center mb-4">
          No contracts. Change plan at any time.
        </p>

        <div class="row justify-content-center mt-3 mb-2">
          <div class="col-auto">
            <nav class="nav btn-group">
              <b-button
                :class="{ active: period === 'monthly' }"
                variant="outline-primary"
                @click="period = 'monthly'"
                >Monthly billing
              </b-button>
              <b-button
                :class="{ active: period === 'yearly' }"
                variant="outline-primary"
                @click="period = 'yearly'"
              >
                Yearly billing
              </b-button>
            </nav>
          </div>
        </div>

        <div class="tab-content">
          <div class="tab-pane fade show active">
            <div class="row py-4">
              <div class="col-sm-3 mb-3 mb-md-0">
                <div class="card text-center h-100">
                  <div class="card-body d-flex flex-column">
                    <div class="mb-4">
                      <h5>Free</h5>
                      <span class="display-4">$0</span>
                    </div>
                    <h6>Includes:</h6>
                    <ul class="list-unstyled">
                      <li class="mb-2">1 owned project</li>
                      <li class="mb-2">500 API calls / mo</li>
                      <li class="mb-2">5k fake records / mo</li>
                      <li class="mb-2">10 MiB storage / project</li>
                    </ul>
                    <div class="mt-auto">
                      <b-button
                        variant="outline-secondary"
                        size="lg"
                        :disabled="currentPlan === 'free'"
                        @click="selectFree()"
                        ><span v-if="processing.free">
                          <b-spinner small></b-spinner>
                          Processing
                        </span>
                        <span v-if="!processing.free">{{
                          currentPlan === "free" ? "Existing Plan" : "Select"
                        }}</span></b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-3 mb-3 mb-md-0">
                <div class="card text-center h-100">
                  <div class="card-body d-flex flex-column">
                    <div class="mb-4">
                      <h5>Developer</h5>
                      <span class="display-4" v-if="plans.developer"
                        >${{ plans.developer.pricing[period].cost }}</span
                      >
                      <span>{{ period === "yearly" ? "/yr" : "/mo" }}</span>
                    </div>
                    <h6>Includes:</h6>
                    <ul class="list-unstyled">
                      <li class="mb-2">10 owned project</li>
                      <li class="mb-2">10k API calls / mo</li>
                      <li class="mb-2">100k fake records / mo</li>
                      <li class="mb-2">50 MiB storage / project</li>
                      <li class="mb-2">Github integration</li>
                    </ul>
                    <div class="mt-auto">
                      <b-button
                        variant="outline-primary"
                        size="lg"
                        :disabled="currentPlan === 'developer'"
                        @click="
                          checkOut(
                            'developer',
                            plans.developer.pricing[period].id
                          )
                        "
                        ><span v-if="processing.developer">
                          <b-spinner small></b-spinner>
                          Processing
                        </span>
                        <span v-if="!processing.developer">{{
                          currentPlan === "developer"
                            ? "Existing Plan"
                            : "Upgrade Now"
                        }}</span></b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-3 mb-3 mb-md-0">
                <div class="card text-center h-100">
                  <div class="card-body d-flex flex-column">
                    <div class="mb-4">
                      <h5>Professional</h5>
                      <span class="display-4" v-if="plans.professional"
                        >${{ plans.professional.pricing[period].cost }}</span
                      >
                      <span>{{ period === "yearly" ? "/yr" : "/mo" }}</span>
                    </div>
                    <h6>Includes:</h6>
                    <ul class="list-unstyled">
                      <li class="mb-2">20 owned project</li>
                      <li class="mb-2">50k API calls / mo</li>
                      <li class="mb-2">250k fake records / mo</li>
                      <li class="mb-2">150 MiB storage / project</li>
                      <li class="mb-2">Github integration</li>
                    </ul>
                    <div class="mt-auto">
                      <b-button
                        variant="outline-primary"
                        size="lg"
                        :disabled="currentPlan === 'professional'"
                        @click="
                          checkOut(
                            'professional',
                            plans.professional.pricing[period].id
                          )
                        "
                        ><span v-if="processing.professional">
                          <b-spinner small></b-spinner>
                          Processing
                        </span>
                        <span v-if="!processing.professional">
                          {{
                            currentPlan === "professional"
                              ? "Existing Plan"
                              : "Upgrade Now"
                          }}</span
                        ></b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-3 mb-3 mb-md-0">
                <div class="card text-center h-100">
                  <div class="card-body d-flex flex-column">
                    <div class="mb-4">
                      <h5>Team</h5>
                      <span class="display-4" v-if="plans.team"
                        >${{ plans.team.pricing[period].cost }}</span
                      >
                      <span>{{ period === "yearly" ? "/yr" : "/mo" }}</span>
                    </div>
                    <h6>Includes:</h6>
                    <ul class="list-unstyled">
                      <li class="mb-2">50 owned project</li>
                      <li class="mb-2">250k API calls / mo</li>
                      <li class="mb-2">500k fake records / mo</li>
                      <li class="mb-2">250 MiB storage / project</li>
                      <li class="mb-2">Github integration</li>
                    </ul>
                    <div class="mt-auto">
                      <b-button
                        variant="outline-primary"
                        size="lg"
                        :disabled="currentPlan === 'team'"
                        @click="checkOut('team', plans.team.pricing[period].id)"
                        ><span v-if="processing.team">
                          <b-spinner small></b-spinner>
                          Processing
                        </span>
                        <span v-if="!processing.team">{{
                          currentPlan === "team"
                            ? "Existing Plan"
                            : "Upgrade Now"
                        }}</span></b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="text-center my-4">
          <h2>Frequently asked questions</h2>
        </div>
        <div class="row">
          <div class="col-md-5 col-sm-6 ml-auto">
            <div class="card">
              <div class="card-body">
                <h5 class="h6 card-title">
                  Do I need a credit card to sign up?
                </h5>
                <p class="mb-0">
                  A credit card is only needed for a paid plan (Developer,
                  Professional, or Team).
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-5 col-sm-6 mr-auto">
            <div class="card">
              <div class="card-body">
                <h5 class="h6 card-title">Do you offer a free trial?</h5>
                <p class="mb-0">
                  Our Free plan is free forever. When you're ready to do more,
                  you can upgrade at any time.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-5 col-sm-6 ml-auto">
            <div class="card">
              <div class="card-body">
                <h5 class="h6 card-title">
                  What if I decide to cancel my plan?
                </h5>
                <p class="mb-0">
                  Your plan can be canceled at any time. We do not use
                  contracts, ever.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-5 col-sm-6 mr-auto">
            <div class="card">
              <div class="card-body">
                <h5 class="h6 card-title">Can I cancel at anytime?</h5>
                <p class="mb-0">
                  Yes, you are free to cancel at any time. Your account will
                  revert back to the Free plan.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import { db } from "@/firebase";
import FirebaseMixin from "../mixins/Firebase";
import UtilsMixin from "../mixins/Utils";
import Users from "../firebase/users";
//import moment from "moment";

export default {
  name: "Pricing",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin],
  //components: { "stripe-card-element": StripeCardElement },
  data() {
    return {
      ref: "users",
      period: "monthly",
      plans: {},
      sessions: {},
      subscription: {},
      processing: {
        free: false,
        developer: false,
        professional: false,
        team: false,
      },
    };
  },
  watch: {
    userUid: {
      immediate: true,
      handler(uid) {
        if (uid) {
          this.bindObject(
            this.ref + "/" + uid + "/subscription",
            null,
            "subscription"
          );
        }
      },
    },
    // plans: {
    //   immediate: true,
    //   handler(plans) {
    //     if (Object.keys(plans).length > 0) {
    //       this.prepareCheckOut();
    //     }
    //   },
    // },
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    ...mapGetters(["stripeKey"]),
    userUid() {
      return this.userProfile.uid;
    },
    // priceIds() {
    //   return Object.keys(this.plans).flatMap((plan) => [
    //     this.plans[plan].pricing.monthly.id,
    //     this.plans[plan].pricing.yearly.id,
    //   ]);
    // },
    currentPlan() {
      if (
        "plan" in this.subscription &&
        this.subscription.status === "active"
      ) {
        if (this.subscription.plan.name === "Team Plan") {
          return "team";
        } else if (this.subscription.plan.name === "Professional Plan") {
          return "professional";
        } else if (this.subscription.plan.name === "Developer Plan") {
          return "developer";
        } else {
          return "free";
        }
      } else {
        return "free";
      }
    },
  },
  firebase: {
    plans: db.ref("meta/plans"),
  },
  methods: {
    ...mapMutations(["setUser", "setProfile"]),
    // prepareCheckOut() {
    //   const u = new Users();
    //   u.createCheckoutSession({ prices: [...this.priceIds] }).then((resp) => {
    //     const sessions = resp.data.sessions;
    //     console.log("Sessions", sessions);
    //     for (const session of sessions) {
    //       this.sessions[session.price] = session.id;
    //     }
    //   });
    // },
    checkOut(plan, price) {
      console.log("Upgrade", plan, price);
      this.processing[plan] = true;
      // eslint-disable-next-line no-undef
      const stripe = Stripe(this.stripeKey);
      const u = new Users();
      if (!this.subscription.id || this.subscription.status !== "active") {
        u.createCheckoutSession({ prices: [price] })
          .then((resp) => {
            const sessionId = resp.data.sessions[0].id;
            stripe
              .redirectToCheckout({ sessionId: sessionId })
              .then((result) => {
                console.log(result);
                this.navigate("success");
              })
              .catch((err) => {
                this.notyf.error(
                  "Unable to process upgrade request. Please try again later."
                );
                console.error(err);
                this.processing[plan] = false;
              });
          })
          .catch((err) => {
            this.notyf.error(
              "Unable to process upgrade request. Please try again later."
            );
            console.error(err);
            this.processing[plan] = false;
          });
      } else {
        u.upgradeSubscription(price)
          .then(() => {
            this.navigate("success");
          })
          .catch((err) => {
            this.notyf.error(
              "Unable to process upgrade request. Please try again later."
            );
            console.error(err);
            this.processing[plan] = false;
          });
      }
    },
    selectFree() {
      this.processing.free = true;
      const u = new Users();
      u.cancelSubscription()
        .then(() => {
          this.navigate("success");
        })
        .catch((err) => {
          this.notyf.error(
            "Unable to process upgrade request. Please try again later."
          );
          console.error(err);
          this.processing.free = false;
        });
    },
  },
};
</script>

<style scoped>
.display-4 {
  font-size: 3rem;
}
</style>
